import { graphql } from 'gatsby';
import React from 'react';
import SessionExpiredPageTemplate, {
  SessionExpiredPageProps,
} from 'templates/SessionExpiredPage';

export const query = graphql`
  query {
    csSessionExpiredPage(page_id: { eq: "quote_and_buy_session_expired_page" }) {
      page_id
      ...SessionExpiredPage
    }
  }
`;

const SessionExpiredPage: React.FC<SessionExpiredPageProps> = ({ data }) => (
  <SessionExpiredPageTemplate data={data} pageType="quoteAndBuy" />
);

export default SessionExpiredPage;
